@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;1,400&display=swap');
@import 'calculator';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.document {
  max-width: 800px;
  background: white;

}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.progress__bar {
  height: 4px;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  background: repeating-linear-gradient(
    to right,
    #ee7752 0%,
    #e73c7e 25%,
    #23a6d5 50%,
    #23d5ab 75%,
    #ee7752 100%
  );
  background-size: 200% auto;
  background-position: 0 100%;
  animation: progress__bar-animation 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes progress__bar-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.load-spinner {
   font-size: 20px;
   position: relative;
   width: 30px;
   height: 10px;
   margin: 150px auto;
   transform: rotate(0deg);
}
 .load-spinner .square {
   width: 1em;
   height: 1em;
   border-radius: 2px;
   position: absolute;
   animation-duration: 0.5s;
   animation-timing-function: ease;
   animation-iteration-count: infinite;
}
 .load-spinner .square.square-1 {
   position: relative;
   left: 0;
   background-image: linear-gradient(to left top, #23a8c3 0%, #18a79d 100%);
   animation-name: step-1;
   animation-duration: 1000ms;
}
 .load-spinner .square.square-2 {
   position: relative;
   left: -1em;
   background-image: linear-gradient(to left top, #23a8c3 0%, #18a79d 100%);
   animation-name: step-2;
   animation-duration: 1000ms;
}
 .load-spinner .square.square-3 {
   position: relative;
   left: 0;
   background-image: linear-gradient(to right bottom, #27acd5 36%, #2382c1 100%);
   animation-name: step-3;
   animation-duration: 1000ms;
}
 @keyframes step-1 {
   0% {
     transform: scale(1.25);
  }
   50% {
     transform: scale(1);
  }
   100% {
     transform: scale(1.25);
  }
}
 @keyframes step-2 {
   0% {
     transform: scale(1);
  }
   50% {
     transform: scale(1.25);
  }
   100% {
     transform: scale(1);
  }
}
 @keyframes step-3 {
   0% {
     transform: scale(1.25);
  }
   50% {
     transform: scale(1);
  }
   100% {
     transform: scale(1.25);
  }
}
 

@media print {
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters  {
    display: none
  }
  .document {
    visibility:  visible
  }
}
