.slider__item {
  max-height: 0;
  max-width: 0;
  opacity: 0;
  // overflow: hidden;
  transition: opacity 0.3s, transform 0.3s;
  &.active {
    opacity: 1;
    max-height: inherit;
    max-width: inherit;
  }
}

.slide__grid-override.slide__grid-override {
  margin: 0;
}
