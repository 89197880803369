
// body {
//   .ccard {
//     margin: 0 auto;
//   }
// }
//

.ccard--secondary {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  opacity: 0;
  transition: opacity 1s;
  background: white;
  padding: 1em;
  font-size: 14px;
}

.ccard {
  --font-size: 12px;
  --ratio: 1.61;
  position: relative;
  margin: 0 auto 3em;
  // min-width: 17em;
  min-width: 12em;
  max-width: 22%;
  box-sizing: border-box;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 3px 6px #000A;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', 'Helvetica';
  padding: 2em 2%;
  box-sizing: border-box;
  overflow: hidden;

  .ccard--secondary-active {
    opacity: 1 !important;
    pointer-events: all;
  }
  &:hover {
    .ccard--secondary {
      opacity: 1;
    }
  }
  
  opacity: 0;
  animation: fade-in linear 0.25s;
  animation-fill-mode: forwards;

  &:nth-child(1) {
  }
  

  &:nth-child(2) {
    animation-delay: 0.03s;
  }


  &:nth-child(3) {
    animation-delay: 0.06s;
  }


  &:nth-child(4) {
    animation-delay: 0.09s;
  }
}

.ccard__subtitle {
  color: #4c7ba2;
  font-size: calc(var(--font-size) * var(--ratio));
  margin-bottom: 2.5em;
  background-color: #001339;
  color: white;
  text-align: center;
  padding: 5px 0.25em;
  border-radius: 20px;
  box-shadow: 0 10px 15px #014A;
  text-transform: uppercase;
}

.ccard__cost {
  color: #0251c9;
  margin: 0 -1em;
  .sub-header {
    font-weight: 500;
    color: #aeaeae;
    margin-top: -1.5em;
  }
  .ccard__title {
    margin: 0 -1em;
    font-size: 54px;
    transform: translate(5px, -20px);
    font-weight: 500;
    font-family: 'Lato' !important;
    text-align: left;
    display: flex;
    justify-content: center;
  }
  span {
    float: left;
    font-size: calc(var(--font-size) * var(--ratio));
    vertical-align: text-top;
  }
}

.ccard__info {
  margin: 3em 0 0;
  font-size: var(--font-size);
}

.ccard__tagline {
  margin: 0 0 10px;
  font-size: var(--font-size);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}


.ccard__divider {
  margin: 0 auto;
  height: minmax(10vh, 10em);
  border: 1px solid black;
  width: 0px;
}

.ccard__counter {
  font-family: helvetica;
  background-color: #001339;
  border-radius: 100%;
  width: 2em;
  height: 2em;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
}


.card__step {
  --separation: 3em;
  --height: 2em;
  min-height: 5em;
  overflow: visible !important;
  margin: var(--separation) auto;
  position: relative;
  &:before {
    font-family: helvetica;
    content: "1";
    position: absolute;
    left: -4em;
    top: calc(50% - 2em);
    // top: --top;
    transform: translate(50%, 50%);
    background: #001339;
    color: white;
    width: 2em;
    height: 2em;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:not(:nth-of-type(4)):after {
    position: absolute;
    content: '';
    border: 1px solid #001339;
    // height: calc(var(--separation) + 2em);
    height: var(--height);
    left: -2em;
    top: calc(50% + 1em + 5px);
  }
  &:nth-of-type(1):before {
    content: '1';
  }

  &:nth-of-type(3):before {
    content: '2';
  }

  &:nth-of-type(4):before {
    content: '3';
  }
  &.disabled:before, &.disabled:after {
    background: #a90b3f;
    border-color: #a90b3f;
  }
}

.progress--container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}




@media (min-width: 1250px) {
  .ccard {
    padding: 2em 5%;
  }
}

@keyframes fade-in {
  0% {
    transform: translateY(4em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes snap-out {
  0% {
  }
  99% {
  }
  100% {
    visibility: hidden;
    height: 0;
  }
}
